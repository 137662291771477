import './App.sass';
import dks_logo from './images/dks_logo.png';
import bastons_color_01 from './images/bastons_color_01.webp';
import san_ti_2 from './images/san_ti_2.webp';
import lock_01 from './images/lock_01.webp';
import buah_01 from './images/buah_01.webp';

function App() {
  return (
    <div className="App">
      <section class="parallax flex flex-col md:bg-cover bg-top bg-no-repeat" id="parallax_bg_1" data-speed="0.3" style={{height:"93vh"}}>
        <div class="content p-1 mb-4 rounded bg-slate-900 bg-opacity-50">
            <h1 class="text-4xl md:text-5xl text-white font-medium">Ann Arbor Martial<span class="md:inline">&nbsp;</span><br class="md:hidden" />and Healing Arts</h1>
        </div>
        <img src={dks_logo} class="bg-white rounded-full p-2" alt="logo" />
      </section>

      <section class="bg-slate-200 text-slate-900">
          <div class="content py-12 md:py-16">
              <div class="flex flex-col md:flex-row md:justify-around gap-9 md:gap-0 md:items-center">
                <img src={bastons_color_01} class="flex-1 max-w-md rounded w-full mb-3 md:mb-1" alt="logo" />
                <div class="flex-1 max-w-lg">
                  <p><span class="italic font-bold text-xl">Ann Arbor Martial and Healing Arts</span> teaches a combination of martial arts, training students in effective combat techniques, healthy body mechanics, and a collected mindset.</p>
                  <p>A number of factors contribute to personal safety, and our goal is to train you to respond to the unexpected.</p>
                  <p>Factors within your control include <em>awareness</em>, <em>what to train</em>, <em>how to train</em>, and <em>how to prepare your nervous system</em> for unpleasant intrusions and everyday stress.</p>
                </div>
              </div>
          </div>
      </section>

      <section class="bg-slate-800 text-white">
          <div class="content py-12 md:py-16">
              <div class="flex flex-col md:flex-row md:justify-around gap-9 md:gap-0 md:items-center">
                <div class="flex-1 max-w-lg">
                  <p class="mb-1"><span class="italic font-bold text-xl">We teach</span> a unique blend of ancient martial and healing arts from South-East and East Asia tried and tested for:</p>
                  <ul class="text-lg list-disc ps-5">
                    <li>Self-defense</li>
                    <li>Law enforcement de-escalation and safety</li>
                    <li>Military applications</li>
                    <li>Internal health and healing</li>
                  </ul>
                  <p>For those interested in pursuing these martials arts further, we serve as a training group of <a href="https://detroitkalisilat.com/" title="Detroit Kali Silat website">Detroit Kali-Silat</a> and <a href="https://courses.zhongdingtaichi.com/" title="Zhong Ding Internal Arts website">Zhong Ding Internal Arts</a> and teach: Kali (Philippines), Silat (Indonesia, Malaysia) and Chinese martial and healing arts (including Xingyiquan and Yang Sheng).</p>
                  <div class="flex justify-center">
                    <a href="https://tinyurl.com/AAMHAtraining" class="text-white" target="_blank" title="AAMHA Class Registration Form">
                      <button class="inline-block px-7 py-3 text-xl font-semibold rounded-lg bg-gradient-to-r from-cyan-400 to-cyan-500">
                        Register!
                      </button>
                    </a>
                  </div>
                </div>
                <img src={san_ti_2} class="flex-1 max-w-md rounded w-full mb-8 md:mb-1" alt="san ti" />
              </div>
          </div>
      </section>

      <section class="parallax flex md:bg-cover bg-top bg-repeat-y" id="parallax_bg_2" data-speed="0.3">
        <div class="content px-5 mb-4 rounded bg-slate-900 bg-opacity-50">
            {/* <h1 class="text-4xl md:text-5xl text-white font-medium italic">Stillness is the mother of movement.</h1> */}
        </div>
      </section>

      <section class="bg-slate-200 text-slate-900">
          <div class="content py-12 md:py-16">
              <div class="flex flex-col md:flex-row md:justify-around gap-9 md:gap-14 md:items-center">
                <img src={buah_01} class="flex-1 max-w-md rounded w-full mb-3 md:mb-1" alt="logo" />
                <div class="flex-1 max-w-lg">
                  <p class="mb-1">To help you achieve your individual goals we cultivate a relaxed, respectful environment. You will learn:</p>
                  <ul class="list-disc ps-5">
                    <li>Elements common to violent encounters</li>
                    <li>Techniques to neutralize common attacks</li>
                    <li>Exercises to shed stress and cultivate health</li>
                    <li>Correct body mechanics</li>
                    <li>The foundations to take these martial arts further if you choose</li>
                  </ul>
                  <p>Kuya <strong>Paul Barron</strong> was authorized to teach by Guru Jeff Davidson after 5 years of dedicated study. He continues to train as a student of Detroit Kali-Silat and Zhong Ding Internal Arts and serves as an assistant instructor in defensive tactics at the Oakland County Police Academy.</p>
                  <p>Kuya <strong>Jacob North</strong> is an authorized instructor of Kali Silat of 8 years experience, under Guru Jeff Davidson. Jacob also continues to train as a student of Detroit Kali-Silat and Zhong Ding Internal Arts.</p>
                </div>
              </div>
          </div>
      </section>

      <section class="bg-slate-800 text-white">
          <div class="content py-12 md:py-16">
              <div class="flex flex-col md:flex-row md:justify-around gap-9 md:gap-0 md:items-center">
                <div class="flex-1 max-w-lg">
                <p><span class="italic font-bold text-xl">In partnership</span> with <a href="https://www.mibudokan.org/" title="Michigan Budokan">Michigan Budokan</a>, we offer free self-defense classes to the Ann Arbor community.</p>
                  <p>To progress through our full curriculum of Kali-Silat, defensive tactics, and internal arts, the fees are $60 per month (four classes) and $20 per individual class.</p>
                  <p>Sign up via email _________ or call _________</p>
                  <p>For both the full curriculum and free community class, you'll need to <a href="#">register via this link</a> for Ann Arbor Martial and Healing Arts, and follow the steps to sign the club waiver. (Huron Valley Aikikai drives Michigan Budokan, so you'll see their page and "Aikido" above the entrance to our space).</p>
                </div>
                <img src={lock_01} class="flex-1 max-w-lg rounded w-full mb-8 md:mb-1" alt="san ti" />
              </div>
          </div>
      </section>

      <section class="bg-slate-200 text-slate-900">
          <div class="content py-12 md:py-16">
              <div class="flex flex-col md:flex-row md:justify-around gap-9 md:gap-0 md:items-center">
                <div class="flex-1 max-w-lg mb-8 md:mb-0">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5904.76199655851!2d-83.77747788873633!3d42.27038977108111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883cb1ce68c2b7bb%3A0xd46b5d0c28807e7e!2s1904%20Federal%20Blvd%2C%20Ann%20Arbor%2C%20MI%2048103!5e0!3m2!1sen!2sus!4v1733933374221!5m2!1sen!2sus" class="aspect-square" style={{border:"0", width:"100%", height:"auto"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div class="flex-1 max-w-lg">
                  <p><strong>Would you like to feel more calm, confident, and resilient</strong> in your everyday life? Our martial arts classes not only teach you essential self-defense skills but also focus on calming your nervous system, reducing stress, and building emotional resilience.</p>
                  <p>Join us and empower yourself to feel confident, relaxed, and in control, no matter what comes your way.</p>
                </div>
              </div>
          </div>
      </section>

      <footer class="bg-slate-800 text-white">
          <div class="content">
            <p>footer content</p>
          </div>
      </footer>

    </div>
  );
}

document.addEventListener('scroll', function () {
  const scrollPosition = window.scrollY;

  // Select all parallax sections
  document.querySelectorAll('.parallax').forEach(section => {
      const speed = section.getAttribute('data-speed') || 0.5; // Adjust speed here
      section.style.backgroundPositionY = `-${scrollPosition * speed}px`;
  });
});

export default App;
